import Vue from 'vue'
import Vuex from 'vuex'
import collapse from './modules/collapse'
import permission from './modules/permission'
import user from './modules/user'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
        collapse,
        permission,
        user
    }
})
