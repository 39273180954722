const collcpse = {
    state: {
        isCollapse: false // 折叠状态
    },
    mutations: {
        // 折叠状态切换
        changeCollapse (state) {
            // alert(1)
            state.isCollapse = !state.isCollapse
            // this.state.isCollapse++
        }
    },
    actions: {

    }
}

export default collcpse
