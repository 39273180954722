import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// import echarts from 'echarts'
// 引入公共样式文件
import './assets/styles/public.scss'
import './utils/permission'
// import VueParticles from 'vue-particles'

Vue.config.productionTip = false
Vue.use(ElementUI)
// Vue.use(VueParticles)
// Vue.prototype.$echarts = echarts //全局注册echarts

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
