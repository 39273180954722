<template>
    <div class="login-container" id="particles" :style="{backgroundImage:`url(${bgurl})`}">
        <div class="card" v-if="showLogin">
            <el-form ref="form" :model="form" class="login-form">
                <img src="../assets/images/logo.png" class="logo">
                <el-form-item label="用户名">
                    <el-input v-model.trim="form.name" placeholder="请输入账号" @keyup.enter.native="login">
                        <i slot="prefix" class="el-input__icon el-icon-user"></i>
                    </el-input>
                </el-form-item>
                <el-form-item label="密码">
                    <el-input
                        v-model.trim="form.password"
                        placeholder="请输入密码"
                        show-password
                        @keyup.enter.native="login">
                        <i slot="prefix" class="el-input__icon el-icon-lock"></i>
                    </el-input>
                </el-form-item>
                <el-form-item label-position="rigth">
                    <el-button type="primary" :loading="loginLoading" @click="login" class="login-btn">登录</el-button>
                </el-form-item>
                <p class="close el-icon-circle-close" @click="close"></p>
            </el-form>
        </div>
        <div class="footer">
            <div class="footer-copyright">
                <p>
                    Copyright © 2019-{{ (new Date()).getFullYear() }} LONGHOPE LAW All Rights Reserved.
                    <a href="http://www.nanhenglaw.com">隐私政策</a>
                    <a href="http://www.nanhenglaw.com">使用条款</a>
                </p>
                <p>如果您有任何关于南衡法律的问题，请<a href="http://www.nanhenglaw.com">点击此处</a></p>
                <p class="icp">
                    ICP证：<a href="https://beian.miit.gov.cn" target="_blank">蜀ICP备19026434号</a>
                </p>
            </div>
            <div class="img-info">
                <span class="img-info-btn el-icon-location-outline"></span>
                <div class="img-info-txt">
                    <p style="font-size:20px;">{{imgInfo.split(/\(|（/)[0]}}</p>
                    <p v-if="imgInfo">{{ imgInfo.split(/\(|（/)[1].slice(0,-1) }}</p>
                </div>
            </div>
        </div>
        <!-- <vue-particles
            color="#fff"
            :particleOpacity="0.8"
            :particlesNumber="80"
            shapeType="circle"
            :particleSize="5"
            linesColor="#fff"
            :linesWidth="1"
            :lineLinked="true"
            :lineOpacity="0.5"
            :linesDistance="150"
            :moveSpeed="3"
            :hoverEffect="true"
            hoverMode="grab"
            :clickEffect="true"
            clickMode="push"
        >
        </vue-particles> -->

    </div>
</template>
<script>
import axios from 'axios'
// import VueParticles from 'vue-particles'
export default {
    // components: {
    //     VueParticles
    // },
    data () {
        return {
            form: {
                name: '',
                password: ''
            },
            loginLoading: false,
            bgurl: '',
            imgInfo: '',
            showLogin: true
        }
    },
    mounted () {
        axios({
            url: process.env.VUE_APP_BASE_API + 'res/bingimg'
        }).then((res) => {
            this.bgurl = res.data.length ? `https://cn.bing.com${res.data[0].url}` : ''
            this.imgInfo = res.data[0].copyright
        })
    },
    methods: {
        login () {
            // console.log('submit!')
            // axios.post('http://lawyer.com/lawyer_api/v1.token/gettoken', {
            // axios.post('http://lawyer.com/', {
            if (this.loginLoading) {
                return false
            }
            if (!this.form.name || !this.form.password) {
                this.$message({
                    message: '请正确填写账号与密码',
                    type: 'error'
                })
                return false
            }
            this.loginLoading = true
            axios({
                method: 'post',
                url: process.env.VUE_APP_BASE_API + 'gettoken/user',
                headers: {
                    Authorization: 'rehack'
                },
                data: {
                    username: this.form.name,
                    password: this.form.password,
                    login_env: 'PC'
                }
            }).then(response => {
                const token = response.data.token
                if (token) {
                    sessionStorage.setItem('token', token)
                    // sessionStorage.setItem('login_user', this.form.name)
                    // console.log('login success')
                    // console.log(sessionStorage.getItem('token'))
                    // this.$router.push('/')
                    this.$router.push({ path: '/' })
                    Notification.requestPermission()
                    // 解决vue-router.esm.js:2065 Uncaught (in promise) Error: Redirected when going from "/login?redirect=%2Fdashboard" to "/dashboard" via a navigation guard.
                    /* .catch(failure => {
                            if (isNavigationFailure(failure, NavigationFailureType.redirected)) {
                                // console.log(failure.to.path) // /dashboard
                                // console.log(failure.from.path) // /login
                            }
                        }) // 登录成功之后重定向到首页 */
                } else {
                    this.$alert('登录校验失败，请联系管理员!', '发生错误', {
                        confirmButtonText: '确定'
                    })
                    this.loginLoading = false
                }
            }).catch(error => {
                // console.log(error.response)
                const bq1 = require('../assets/images/bq1.jpg')
                if (error.response.data.msg) {
                    this.$alert(error.response.data.msg, '发生错误', {
                        confirmButtonText: '确定'
                    })
                } else {
                    this.$alert(`<img src='${bq1}' width=85>`, '发生错误', {
                        confirmButtonText: '确定',
                        dangerouslyUseHTMLString: true
                    })
                }
                this.loginLoading = false
            })
        },
        close () {
            this.showLogin = false
        }
    }
}
</script>
<style lang="scss" scoped>
$bg: #eee;
// $bgurl: "/static/images/login-bg.png";
// $bgurl: "../assets/images/login-bg3.jpg";
.login-container {
    height: 100%;
    width: 100%;
    background-color: $bg;
    // background: url($bgurl) no-repeat center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    overflow: hidden;
    .card{
        position: relative;
        padding: 20px;
        width: 270px;
        height: 340px;
        left: 50%;
        top: 40%;
        transform: translate(-50%, -50%);
        border-radius: 10px;
        background-color: #fff;
        box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1);
    }
    .login-form {
        width: 100%;
        height: 100%;
        .logo {
            width: 70px;
            display: block;
            margin: 0 auto;
        }
        .el-input__icon{
            color: #FB7299;
        }
        .login-btn{
            width: 90px;
            display: block;
            border: 0;
            margin: 0 auto;
            background-image: linear-gradient(200deg, #78f, #f78);
        }
        .login-btn:hover{
            background: #FB7299;
        }
        .close{
            position: absolute;
            top: 5px;
            right: 5px;
            font-size: 20px;
            color: #bbb;
            cursor: pointer;
        }

    }
    .footer {
        width: 100%;
        position: absolute;
        bottom: 0;
        .footer-copyright {
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translate(-50%, -50%);
            text-align: center;
            color: #aaa;
            font-size: 12px;
            a {color: #aaa;margin-right: 8px;}
        }
        .img-info{
            position: absolute;
            bottom: 20px;
            right: 5%;
            color: #fff;
            .img-info-txt{
                width: 200px;
                position: absolute;
                bottom: 40px;
                right: -55%;
                background-color: rgba(34,34,34,.8);
                padding: 12px;
                border-radius: 5px;
                visibility:hidden;
                opacity: 0;
                font-size: 14px;
                transition: all 0.2s ease-in;
                font-size: 12px;
            }
            .img-info-btn{
                display: block;
                text-align: center !important;
                padding:0 15px;
                line-height: 30px;
                background-color: rgba(34,34,34,.7);
                border-radius: 4px;
                margin: 0 .125rem;
                text-align: left;
                cursor: pointer;
                font-size: 13px;
            }
            .img-info-btn:hover + .img-info-txt{
                visibility:visible;
                opacity: 1;
            }
        }
    }
    @keyframes rotate {
        0% {
            transform: rotateY(0);
        }
        100% {
            transform: rotateY(180deg);
        }
    }

    @keyframes rotate-reverse {
        0% {
            transform: rotateY(180deg);
        }
        100% {
            transform: rotateY(0);
        }
    }
}
</style>
