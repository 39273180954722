// 根据权限动态添加路由表
import router from '../router/'
import store from '../store/'
// import { Message } from 'element-ui'
// import { getInfo } from '@/api/user'
// import NProgress from 'nprogress'
// import 'nprogress/nprogress.css'
// NProgress.configure({ showSpinner: false })

const whiteList = ['/login', '/auth-redirect'] // 白名单
// window.sessionStorage.setItem('hasAddRoutes', 'false')
// 每当路由跳转（路由改变，切换菜单）之前会执行这里的逻辑
router.beforeEach(async (to, from, next) => {
    const token = window.sessionStorage.getItem('token')

    // NProgress.start()
    // const hasRoles = store.getters.roles && store.getters.roles.length > 0
    if (token) {
        /* has token */
        if (to.path === '/login') {
            console.log('login')
            next({ path: '/' })
            // next()
            // NProgress.done()
        } else {
            const hasUserInfo = store.state.user.userInfo
            // 判断是否已经添加了动态路由表 当刷新页面或修改url地址的时候hasUserInfo为null(因为vuex在刷新的时候数据会消失) 然后会重新执行动态添加路由表router.addRoute；如果是浏览器回退或点击导航(this.$router.push)、退出登录等就不会router.addRoute ,退出登录的时候要删除路由表，否则换用户登录会使用之前用户的路由表，也可以退出登录的时候刷新下页面来删除路由表
            if (hasUserInfo) {
                next()
            } else {
                // console.log('no-hasUserInfo')
                try {
                    // 根据角色获取路由表
                    // const { roles } = await store.dispatch('getUserInfo') // 用户身份 异步请 求只取userinfo中的roles数据
                    // const accessRoutes = await store.dispatch('generateRoutes', roles)
                    // 根据后端菜单数据生成路由表
                    const { menus } = await store.dispatch('getUserInfo') // 只取userinfo中的menus数据
                    const accessRoutes = await store.dispatch('generateServerRoutersMap', menus)
                    // console.log('路由表：', accessRoutes)
                    // 动态添加路由表
                    accessRoutes.forEach(route => router.addRoute(route))
                    // console.log('动态添加路由表')
                    router.push(to.path)
                    // next({ ...to, replace: true }) // hack方法 确保addRoute已完成 会重新进入router.beforeEach 用next()会导致导航故障
                } catch (error) {
                    console.log(error)
                    // await store.dispatch('user/resetToken')
                    // window.sessionStorage.removeItem('token')
                    // Message.error(error || 'Has Error')
                    // next(`/login?redirect=${to.path}`)
                    // NProgress.done()
                }
            }
        }
    } else {
        /* has no token */
        if (whiteList.indexOf(to.path) !== -1) { // 在免登录白名单，直接进入
            next()
        } else {
            // console.log('不是login')
            next(`/login?redirect=${to.path}`) // 否则全部重定向到登录页 将跳转的路由path作为参数，登录成功后跳转到该路由
            // NProgress.done()
        }
    }
})

router.afterEach(() => {
    // NProgress.done()
})
