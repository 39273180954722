import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
// import router from '../router'
// import { MessageBox } from 'element-ui'
// import store from '@/store'

// const token = window.sessionStorage.getItem('token')
// console.log(token)
// 创建axios实例
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // api的base_url
    timeout: 0 // 请求超时时间
})

// http request拦截器
service.interceptors.request.use(req => {
    // Do something before request is sent
    const token = window.sessionStorage.getItem('token')
    token && (req.headers.Authorization = 'Bearer ' + token)// 让每个请求携带header token

    return req
}, error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
})

// http response 拦截器
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
    */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        const res = response.data
        return res
    },
    error => {
        // console.log(error) // for debug
        // 20002 token过期或无效 20005 登录超时  20008 账号在另一处登录
        if (error.response.data.msg) {
            // console.log(error.response)
            if (error.response.data.error_code === 20002 || error.response.data.error_code === 20005 || error.response.data.error_code === 20008) {
                MessageBox.confirm(`${error.response.data.msg},您可以取消继续留在该页面，或者重新登录`, '登录超时', {
                    confirmButtonText: '重新登录',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    window.sessionStorage.removeItem('token')
                    location.reload()
                }).catch(() => {
                    // console.log('取消') // 取消后会再次弹出的bug
                })
            } else {
                MessageBox.alert(error.response.data.msg, '发生错误(Response interceptor)', {
                    confirmButtonText: '确定'
                })
            }
        } else {
            // console.log(error.response)
            Message({
                message: error,
                type: 'error',
                duration: 5 * 1000
            })
        }
        return Promise.reject(error) // 返回接口返回的错误信息
    })

export default service
