<template>
    <el-aside class="aside" :class="isCollapse?'menu-collapsed':'menu-expanded'">
        <div v-show="!isCollapse" class="logo">
            <img src="@/assets/images/logo.png">
        </div>
        <el-menu
            class="el-menu-vertical-demo"
            :collapse="isCollapse"
            text-color="#2b2b2b"
            active-text-color="#ff5a00"
            background-color="#e8ecef"
            :collapse-transition="false"
            :default-active="$route.path"
            router
        >
            <!-- <el-menu-item index="/dashboard">
                <i class="el-icon-menu"></i>
                <span slot="title">首页</span>
            </el-menu-item>
            <el-submenu index="kd">
                <template slot="title">
                    <i class="el-icon-phone"></i>
                    <span>营销中心</span>
                </template>
                <el-menu-item index="/marketing/manage">
                    <i class="el-menu-item__icon-arrow el-icon-arrow-right"></i>营销管理
                </el-menu-item>
            </el-submenu>
            <el-submenu index>
                <template slot="title">
                    <i class="el-icon-coin"></i>
                    <span>项目中心</span>
                </template>
                <el-menu-item index="/projects/manage">
                    <i class="el-menu-item__icon-arrow el-icon-arrow-right"></i>项目管理
                </el-menu-item>
            </el-submenu>
            <el-submenu index="sys">
                <template slot="title">
                    <i class="el-icon-setting"></i>
                    <span>系统设置</span>
                </template>
                <el-menu-item index="/settings/staff">职员管理</el-menu-item>
                <el-menu-item index="/settings/data-dictionary">数据字典</el-menu-item>
            </el-submenu>-->
            <!-- 一级单页菜单 -->
            <el-menu-item v-for="item in singleMenu" :key="item.path" :index="item.redirect">
                <i class="menu-icon" :class="item.meta.iconCls" />
                <span slot="title">{{ item.meta.title }}</span>
            </el-menu-item>
            <!-- 一级菜单有子节点  -->
            <el-submenu v-for="(item,index) in oneLevelMenu" :key="index" :index="item.path">
                <template slot="title">
                    <i class="menu-icon" :class="item.meta?item.meta.iconCls:item.iconCls"/>
                    <span>{{ item.meta?item.meta.title:item.title }}</span>
                </template>
                <el-menu-item v-for="(childItem,index) in item.children" :key="index" :index="item.path+'/'+childItem.path">
                    <i class="el-menu-item__icon-arrow el-icon-arrow-right" />{{ childItem.meta?childItem.meta.title:childItem.title }}
                </el-menu-item>
            </el-submenu>
        </el-menu>
    </el-aside>
</template>
<script>
import store from '@/store'
export default {
    name: 'GlobalAside',
    data () {
        return {
            routes: store.state.permission.routes
        }
    },
    computed: {
        isCollapse () {
            return store.state.collapse.isCollapse
        },
        // 过滤一级单页导航菜单
        singleMenu: function () {
            return this.routes.filter(function (menu) {
                return menu.type === 'singleLevel'
            })
        },
        // 过滤一级菜单有子节点的
        oneLevelMenu () {
            return this.routes.filter(function (menu) {
                return menu.type !== 'singleLevel' && menu.children
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.logo {
    text-align: center;
    margin-top: 10px;
    img {
        max-width: 50%;
    }
}
.el-aside {
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #e8ecef;
    height: 100vh;
    text-align: left;
    box-shadow: 2px 0 4px rgba(0, 21, 41, 0.35);
    z-index: 1;
    color: #fff;
    transition: flex 0.2s linear;
    // 自定义滚动条
    &::-webkit-scrollbar{
        width : 5px;  /*滚动条整体：高宽分别对应横竖滚动条的尺寸*/
        height: 1px; //针对横向滚动条
    }
    &::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/
        border-radius: 10px;
        background: linear-gradient(0, #8aaaff, #fa8cff);
    }
    &::-webkit-scrollbar-track {
        /*滚动条里面轨道*/
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: #ededed;
        border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover{
        background-color: rgb(173, 135, 235);
        background-image: -webkit-linear-gradient(
            45deg,
            rgba(255, 255, 255, 0.4) 25%,
            transparent 25%,
            transparent 50%,
            rgba(255, 255, 255, 0.4) 50%,
            rgba(255, 255, 255, 0.4) 75%,
            transparent 75%,
            transparent
        );
    }
    .el-menu {
        border-right: none;
        .menu-icon{
            margin-right: 2px;
            color: #777;
        }
        .el-submenu {
            border-bottom: 1px solid #d4d8da;
            border-top: 1px solid #fff;
            font-weight: bold;
            ::v-deep .el-submenu__title{
                height: 45px;
                line-height: 45px;
                padding-left: 8px!important;
                .el-submenu__icon-arrow{
                    position: unset;
                    margin-top: 0;
                    margin-left: 3px;
                }
            }
            .el-menu-item{
                font-weight: normal;
                height: 22px;
                line-height: 22px;
                font-size: 12px;
                padding-left: 20px!important;
            }
        }
        .el-menu-item__icon-arrow {
            vertical-align: baseline;
        }
    }
}

// 折叠后宽度
.menu-collapsed {
    flex: 0 0 64px;
    width: 64px;
}
// 菜单宽度
.menu-expanded {
    flex: 0 0 130px;
    width: 130px;
}
</style>
