import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Layout from '@/components/Layout/Index'
Vue.use(VueRouter)

// 不需要权限显示的公共路由菜单
export const constantRoutes = [
    {
        path: '/login',
        name: 'Login',
        component: Login,
        hidden: true
    },
    {
        path: '/',
        // name: 'Layout',
        component: Layout,
        redirect: '/dashboard', // 首页重定向到dashboard欢迎页面
        children: [{
            path: '/dashboard',
            name: 'dashboard',
            component: () => import('@/views/dashboard/index')
        }],
        meta: {
            title: '首页',
            iconCls: 'el-icon-s-home'
        },
        type: 'singleLevel'
    },
    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        // component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
        component: () => import('@/views/About'),
        hidden: true
    },
    { path: '*', component: () => import('@/views/NotFound') }
]

// export const asyncRoutes = [
//     {
//         path: '/marketing',
//         redirect: '/marketing/mycustomer',
//         component: Layout,
//         children: [
//             {
//                 path: 'marketing/originalcustomer',
//                 name: 'originalcustomer',
//                 meta: {
//                     title: '原始客户',
//                     roles: ['系统管理员']
//                 }, // 页面需要的权限
//                 component: () => import('@/views/marketing/OriginalCustomer')
//             },
//             {
//                 path: 'marketing/receivecustomer',
//                 name: 'receivecustomer',
//                 meta: {
//                     title: '客户领取',
//                     roles: ['系统管理员', '律师主管', '律师']
//                 }, // 页面需要的权限
//                 component: () => import('@/views/marketing/ReceiveCustomer')
//             },
//             {
//                 path: 'marketing/mycustomer',
//                 name: 'mycustomer',
//                 meta: {
//                     title: '我的客户'
//                 }, // 页面需要的权限
//                 component: () => import(/* webpackChunkName: "mycustomer" */'@/views/marketing/MyCustomer')
//             }
//         ],
//         meta: {
//             title: '客服中心',
//             iconCls: 'el-icon-phone-outline',
//             roles: ['系统管理员', '商务中心主管', '商务助理', '运营主管', '运营中心客服', '渠道主管', '渠道经理']
//         }, // 页面需要的权限
//         pid: 0
//     },
//     {
//         path: '/projects',
//         redirect: '/projects/manage',
//         component: Layout,
//         children: [
//             {
//                 path: 'projects/createlawcase',
//                 name: 'createlawcase',
//                 meta: {
//                     title: '案件接入'
//                 },
//                 component: () => import('@/views/projects/CreateLawCase')
//             },
//             {
//                 path: 'projects/handlecase',
//                 name: 'handlecase',
//                 meta: {
//                     title: '案件办理',
//                     roles: ['系统管理员', '律师主管', '律师', '运营主管']
//                 },
//                 component: () => import('@/views/projects/HandleLawCase')
//             },
//             {
//                 path: 'recyclebin',
//                 name: 'recyclebin',
//                 meta: {
//                     title: '项目回收站',
//                     roles: ['系统管理员']
//                 },
//                 component: () => import('@/views/projects/RecycleBin')
//             }
//         ],
//         meta: {
//             title: '项目中心',
//             iconCls: 'el-icon-coin',
//             roles: ['系统管理员', '律师主管', '律师', '运营主管', '财务-出纳', '财务-会计', '渠道主管', '渠道经理']
//         } // 页面需要的权限

//     },
//     {
//         path: '/customerdata',
//         redirect: '/customerdata/followup',
//         component: Layout,
//         children: [
//             {
//                 path: 'customerdata/clientinfo',
//                 name: 'clientinfo',
//                 meta: {
//                     title: '客户资料统计表'
//                 },
//                 component: () => import('@/views/customerdata/ClientInfo')
//             },
//             {
//                 path: 'customerdata/followup',
//                 name: 'followup',
//                 meta: {
//                     title: '历史跟进记录表'
//                 },
//                 component: () => import('@/views/customerdata/Followup')
//             },
//             {
//                 path: 'customerdata/dataanalysis',
//                 name: 'dataanalysis',
//                 meta: {
//                     title: '客服数据分析',
//                     roles: ['系统管理员', '商务中心主管', '运营主管']
//                 },
//                 component: () => import('@/views/customerdata/DataAnalysis')
//             },
//             {
//                 path: 'customerdata/appointmentview',
//                 name: 'appointmentview',
//                 meta: {
//                     title: '预约视图统计',
//                     roles: ['系统管理员', '商务中心主管', '商务助理', '运营主管', '运营中心客服']
//                 },
//                 component: () => import('@/views/customerdata/AppointmentView')
//             }
//         ],
//         meta: {
//             title: '商务数据',
//             iconCls: 'el-icon-data-line',
//             roles: ['系统管理员', '商务中心主管', '商务助理']
//         } // 页面需要的权限

//     },
//     {
//         path: '/projectData',
//         redirect: '/projectData/CaseData',
//         component: Layout,
//         meta: {
//             title: '项目数据',
//             iconCls: 'el-icon-s-data',
//             roles: ['系统管理员', '运营主管']
//         },
//         children: [
//             {
//                 path: 'casedata',
//                 name: 'casedata',
//                 meta: {
//                     title: '案件数据统计'
//                 },
//                 component: () => import('@/views/projectData/CaseData')
//             },
//             {
//                 path: 'mattersdata',
//                 name: 'mattersdata',
//                 meta: {
//                     title: '案件办理统计'
//                 },
//                 component: () => import('@/views/projectData/MattersData')
//             },
//             {
//                 path: 'evidencedata',
//                 name: 'EvidenceData',
//                 meta: {
//                     title: '证据文书统计'
//                 },
//                 component: () => import('@/views/projectData/EvidenceData')
//             },
//             {
//                 path: 'derivativecase',
//                 name: 'derivativecase',
//                 meta: {
//                     title: '衍生案件统计'
//                 },
//                 component: () => import('@/views/projectData/DerivativeCase')
//             },
//             {
//                 path: 'pointslog',
//                 name: 'pointslog',
//                 meta: {
//                     title: '律师计分日志'
//                 },
//                 component: () => import('@/views/projectData/PointsLog')
//             },
//             {
//                 path: 'lawyerworkload',
//                 name: 'lawyerworkload',
//                 meta: {
//                     title: '律师绩效奖金'
//                 },
//                 component: () => import('@/views/projectData/LawyerWorkload')
//             }
//         ]
//     },
//     {
//         path: '/financeData',
//         redirect: '/financeData/ChargeDetail',
//         component: Layout,
//         children: [
//             {
//                 path: 'chargedetail',
//                 name: 'chargedetail',
//                 meta: {
//                     title: '收款明细'
//                 },
//                 component: () => import('@/views/financeData/ChargeDetail')
//             },
//             {
//                 path: 'financemanage',
//                 name: 'financemanage',
//                 meta: {
//                     title: '财务管理'
//                 },
//                 component: () => import('@/views/financeData/Manage')
//             }
//         ],
//         meta: {
//             title: '财务数据',
//             iconCls: 'el-icon-s-finance',
//             roles: ['系统管理员', '财务-出纳', '财务-会计']
//         }

//     },
//     {
//         path: '/archives',
//         redirect: '/archives/manage',
//         component: Layout,
//         children: [
//             {
//                 path: 'manage',
//                 meta: {
//                     title: '档案管理'
//                 },
//                 component: () => import('@/views/archives/Manage')
//             }
//         ],
//         meta: {
//             title: '档案中心',
//             iconCls: 'el-icon-document-copy',
//             roles: ['系统管理员', '运营主管']
//         }

//     },
//     {
//         path: '/thinktank',
//         redirect: '/thinktank/qa',
//         component: Layout,
//         children: [
//             {
//                 path: 'qa',
//                 meta: {
//                     title: '知识问答'
//                 },
//                 component: () => import('@/views/thinktank/QA')
//             },
//             {
//                 path: 'cases',
//                 meta: {
//                     title: '精选案例'
//                 },
//                 component: () => import('@/views/thinktank/cases')
//             },
//             {
//                 path: 'procedure',
//                 meta: {
//                     title: '流程方法'
//                 },
//                 component: () => import('@/views/thinktank/procedure')
//             }
//         ],
//         meta: {
//             title: '南衡智库',
//             iconCls: 'el-icon-magic-stick'
//         }
//     },
//     {
//         path: '/personal',
//         redirect: '/personal/PersonalData',
//         component: Layout,
//         children: [
//             {
//                 path: 'PersonalData',
//                 meta: {
//                     title: '个人数据'
//                 },
//                 component: () => import('@/views/personal/PersonalData')
//             },
//             {
//                 path: 'todoList',
//                 meta: {
//                     title: '我的待办'
//                 },
//                 component: () => import('@/views/personal/TodoList')
//             },
//             {
//                 path: 'TodoListData',
//                 meta: {
//                     title: '待办统计',
//                     roles: ['系统管理员', '运营主管', '运营中心客服']
//                 },
//                 component: () => import('@/views/personal/TodoListData')
//             }
//         ],
//         meta: {
//             title: '个人中心',
//             iconCls: 'el-icon-s-custom',
//             roles: ['系统管理员', '商务中心主管', '商务助理', '运营主管', '渠道主管', '渠道经理', '运营中心客服', '律师主管', '律师', '财务-出纳', '财务-会计']
//         }

//     },
//     {
//         path: '/settings',
//         redirect: '/settings/staff',
//         component: Layout,
//         children: [
//             {
//                 path: 'staff',
//                 name: 'staff',
//                 meta: {
//                     title: '职员管理',
//                     roles: ['系统管理员', '运营主管']
//                 }, // 页面需要的权限
//                 component: () => import('@/views/settings/Staff')
//             },
//             {
//                 path: 'data-dictionary',
//                 name: 'dataDictionary',
//                 meta: {
//                     title: '数据字典',
//                     roles: ['系统管理员']
//                 }, // 页面需要的权限
//                 component: () => import('@/views/settings/DataDictionary')
//             },
//             {
//                 path: 'auth',
//                 name: 'Auth',
//                 meta: {
//                     title: '权限设置',
//                     roles: ['系统管理员']
//                 }, // 页面需要的权限
//                 component: () => import('@/views/settings/Auth')
//             },
//             {
//                 path: 'pointrules',
//                 name: 'pointrules',
//                 meta: {
//                     title: '计分规则',
//                     roles: ['系统管理员']
//                 }, // 页面需要的权限
//                 component: () => import('@/views/settings/PointRules')
//             },
//             {
//                 path: 'Notice',
//                 name: 'Notice',
//                 meta: {
//                     title: '公告通知',
//                     roles: ['系统管理员']
//                 }, // 页面需要的权限
//                 component: () => import('@/views/settings/Notice')
//             },
//             {
//                 path: 'Oplog',
//                 name: 'Oplog',
//                 meta: {
//                     title: '系统日志',
//                     roles: ['系统管理员']
//                 }, // 页面需要的权限
//                 component: () => import('@/views/settings/Oplog')
//             },
//             {
//                 path: 'LoginLog',
//                 name: 'LoginLog',
//                 meta: {
//                     title: '登录日志',
//                     roles: ['系统管理员']
//                 }, // 页面需要的权限
//                 component: () => import('@/views/settings/LoginLog')
//             }
//         ],
//         meta: {
//             title: '系统设置',
//             iconCls: 'el-icon-setting',
//             roles: ['系统管理员', '运营主管']
//         } // 页面需要的权限

//     },
//     { path: '*', component: () => import('@/views/NotFound'), hidden: true }
// ]

// 实例化vue的时候只挂载constantRouter
const createRouter = () => new VueRouter({
    mode: 'history',
    // base: process.env.BASE_URL,
    base: process.env.VUE_APP_PUBLIC_PATH ? process.env.VUE_APP_PUBLIC_PATH : '/',
    routes: constantRoutes
})
const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter () {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}

export default router
