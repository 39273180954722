import request from '@/utils/request'

export function login (data) {
    return request({
        url: 'user/login',
        method: 'post',
        data
    })
}

export function getInfo () {
    return request({
        url: 'commom/sysuserinfo',
        method: 'get'
    })
}

export function logout () {
    return request({
        url: 'user/logout',
        method: 'post'
    })
}
// 修改登陆密码
export function resetPassword (data) {
    return request({
        url: 'system/resetpassword',
        method: 'post',
        data
    })
}
