class Tools {
    // 为数据添加新节点 递归
    addNode (data, newNode, newData = []) {
        data.forEach(item => {
            if (item.children) {
                this.addNode(item.children, newNode, newData)
            } else {
                item.children = newNode
            }
        })
    }

    // 把树结构数据转成平级结构 递归
    normalizr (data, key, arr = []) {
        data.forEach(item => {
            arr.push({
                id: item.id,
                [key]: item[key],
                pid: item.pid,
                sort: item.sort,
                status: item.status
            })
            if (item.children) {
                this.normalizr(item.children, key, arr)
            }
        })
        return arr
    }

    // 生成树结构
    makeTree (data) {
        const map = {}
        for (const iterator of data) {
            map[iterator.id] = iterator
        }
        const res = []
        for (const iterator of data) {
            const parent = map[iterator.pid]
            if (parent) {
                (parent.children || (parent.children = [])).push(iterator)
            } else {
                res.push(iterator)
            }
        }
        return res
    }
}

export default Tools
