import { getInfo } from '@/api/user'
import { resetRouter } from '@/router'

const user = {
    state: {
        userInfo: null
    },
    mutations: {
        setUserInfo (state, userInfo) {
            state.userInfo = userInfo
        }
    },
    actions: {
        // 获取用户信息
        getUserInfo ({ commit }) {
            return new Promise((resolve, reject) => {
                getInfo().then(response => {
                    const data = response
                    if (!data) {
                        reject(new Error('error'))
                    }
                    // const departmentName = data.department.department_name

                    commit('setUserInfo', data)
                    // console.log(data)
                    // debugger
                    resolve(data)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // 退出登录
        logout ({ commit, state }) {
            return new Promise((resolve) => {
                // commit('SET_TOKEN', '')
                sessionStorage.removeItem('token')
                commit('setUserInfo', null) // 不加这个不同用户登录菜单不会刷新
                // removeToken()
                resetRouter()
                resolve()
            })
        }
    }
}

export default user
