<template>
    <el-container>
        <GlobalAside />
        <el-container direction="vertical">
            <!-- el-container没有el-header或el-footer时，全部子元素会左右排列，如果要强制垂直上下排列加上direction="vertical"属性 -->
            <GlobalHeader />
            <el-main>
                <transition name="fade-transform" mode="out-in">
                    <keep-alive>
                        <router-view />
                    </keep-alive>
                </transition>
            </el-main>
            <FooterInfo v-if="showFooter" />
        </el-container>
    </el-container>
</template>

<script>
import GlobalAside from './GlobalAside'
import GlobalHeader from './GlobalHeader'
import FooterInfo from './FooterInfo'

export default {
    name: 'Layout',
    components: { GlobalAside, GlobalHeader, FooterInfo },
    data () {
        return {
            // showFooter: false
        }
    },
    computed: {
        // 根据当前路由地址判断是否需要显示footer组件
        showFooter () {
            if (this.$route.path === '/dashboard') {
                //eslint-disable-line
                return true
            }
            return false
        }
    }
}
</script>

<style lang="scss">
.el-container {
    height: 100%;
    overflow: hidden;
}
.el-main {
    background-color: #f6f6f6;
    width: 100%;
    height: 100%;
    overflow: hidden !important;
    padding: 10px!important;
}
.fade-transform-leave-active,
.fade-transform-enter-active {
    transition: all 0.2s;
}
.fade-transform-enter {
    opacity: 0;
    transform: translateX(-30px);
}
.fade-transform-leave-to {
    opacity: 0;
    transform: translateX(30px);
}
</style>
