import { Notification, Message } from 'element-ui'
import sounds from '@/assets/media/notice.mp3'
import notification from '@/utils/notification'
export function socketConnect (uid, msgfn = () => {}) {
    const host = `${process.env.VUE_APP_WS_SERVER}?uid=${uid}`
    const ws = new WebSocket(host)
    const audio = new Audio(sounds)
    // ws.onopen = function () {
    //     // Web Socket 已连接上，使用 send() 方法发送数据
    //     console.log('数据发送中...')
    // },
    ws.onopen = function (evt) {
        // Web Socket 已连接上，使用 send() 方法发送数据
        console.log('Connected to WebSocket server.')
    }
    ws.onmessage = function (evt) {
        // 接收数据
        // evt.data //string
        // console.log(evt.data)
        try {
            const data = JSON.parse(evt.data)
            if (data.msg && data.notify_type === 'todo') {
                audio.play()
                // 弹窗通知
                Notification.warning({
                    title: '待办通知',
                    duration: 0,
                    dangerouslyUseHTMLString: true,
                    message: `
                        <div>${['一般', '重要', '紧急'][data.msg.level - 1]}事项：${data.msg.task_content}</div>
                        <p>待办时间：${data.msg.task_time}</p>
                        <p>地点：${data.msg.task_addr || ''}</p>
                    `
                })
                // 桌面通知
                notification('你有一个待办事项', `${['一般', '重要', '紧急'][data.msg.level - 1]}事项：${data.msg.task_content}`)
            }
        } catch (error) {
            // console.log(error)
            console.log(evt.data)
        }
    }
    // ws.onmessage = msgfn(evt)
    ws.onclose = function () {
        // console.log('Disconnected')
        Message.warning({
            message: 'Socket连接已关闭，通知服务中断！',
            duration: 5000
        })
    }
    ws.onerror = function (evt, e) {
        // console.log('Error occured: ' + evt.data)
        Message.warning({
            message: 'Socket服务连接失败！',
            duration: 5000
        })
    }
}
// 自动播放声音参考
// https://developer.mozilla.org/en-US/docs/Web/Media/Autoplay_guide
