// HTML5 Web Notification桌面通知
import logo from '@/assets/images/logo.png'
export default function (title, msg) {
    if (!('Notification' in window)) {
        alert('This browser does not support desktop notification')
    } else if (Notification.permission === 'granted') { // 如果用户已经授权接受通知
        // If it's okay let's create a notification
        /* eslint-disable no-new */
        new Notification(title, {
            body: msg,
            silent: false,
            requireInteraction: true, // 不关闭
            icon: logo
        })
    } else if (Notification.permission !== 'denied') { // 包括default和denied两个状态，如果是denied，则无法重新申请通知授权
        // alert(Notification.permission)
        Notification.requestPermission().then(function (permission) { // 否则我们需要向用户获取权限
            // 如果用户接受权限，我们就可以发起一条消息
            if (permission === 'granted') {
                /* eslint-disable no-new */
                new Notification(title, {
                    body: msg,
                    silent: true,
                    requireInteraction: true,
                    icon: logo
                })
            }
        })
    }
}
