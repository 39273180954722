<template>
    <el-header height="50px">
        <div>
            <div class="collapse" @click="collapse">
                <i class="el-icon-s-fold" />
            </div>
        </div>
        <div class="header-right">
            <!-- <el-badge class="sys-msg" :value="msgNum ? msgNum : ''"> -->
            <!-- <i class="el-icon-bell"></i> -->
            <el-button
                type="danger"
                size="mini"
                plain
                round
                icon="el-icon-warning-outline"
                style="margin-right:10px"
                @click="goabout"
            >关于</el-button>
            <!-- <el-badge class="sys-msg" :value="msgNum ? msgNum : ''" slot="reference">
                <el-button type="info" icon="el-icon-bell" size="small" round plain @click="dialogTableVisible = false">消息</el-button>
            </el-badge>-->
            <el-dropdown>
                <span class="el-dropdown-link">
                    {{ groupName+' '+realName }}
                    <!-- {{groupName}} -->
                    <i class="el-icon-arrow-down el-icon--right" />
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="dialogVisible = true">修改密码</el-dropdown-item>
                    <el-dropdown-item @click.native="logout">退出</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
        <template>
            <el-dialog title="修改登陆密码" :visible.sync="dialogVisible" width="25%">
                <el-form
                    ref="ruleForm"
                    :model="ruleForm"
                    status-icon
                    :rules="rules"
                    label-width="100px"
                    class="demo-ruleForm"
                >
                    <el-form-item label="新密码" prop="pass">
                        <el-input v-model="ruleForm.pass" type="password" autocomplete="off" />
                    </el-form-item>
                    <el-form-item label="确认新密码" prop="checkPass">
                        <el-input v-model="ruleForm.checkPass" type="password" autocomplete="off" />
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
                        <el-button @click="resetForm('ruleForm')">清除</el-button>
                    </el-form-item>
                </el-form>
            </el-dialog>
        </template>
    </el-header>
</template>
<script>
import store from '@/store'
// import axios from 'axios'
import { socketConnect } from '@/utils/websocket'
import * as userApi from '@/api/user.js'
export default {
    name: 'GlobalHeader',
    store,
    data () {
        const validatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入密码'))
            } else {
                if (this.ruleForm.checkPass !== '') {
                    this.$refs.ruleForm.validateField('checkPass')
                }
                callback()
            }
        }
        const validatePass2 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码'))
            } else if (value !== this.ruleForm.pass) {
                callback(new Error('两次输入密码不一致!'))
            } else {
                callback()
            }
        }
        return {
            timer: null, // 定时器
            msgNum: null, // 消息数量
            expireFollow: [], // 过期跟进数据
            dialogVisible: false,
            realName: store.state.user.userInfo.realname,
            groupName: store.state.user.userInfo.dept.department_name,
            ruleForm: {
                pass: '',
                checkPass: ''
            },
            rules: {
                pass: [{ validator: validatePass, trigger: 'blur' }],
                checkPass: [{ validator: validatePass2, trigger: 'blur' }]
            }
        }
    },
    created () {
        // this.queryExpireFollow()
        socketConnect(store.state.user.userInfo.id)// 建立websocket连接
    },
    methods: {
        // 折叠导航栏
        collapse () {
            // console.log(store.state)
            // 改变vuex状态
            store.commit('changeCollapse')
        },
        // 退出登录
        async logout () {
            await this.$store.dispatch('logout')
            this.$router.push(`/login?redirect=${this.$route.fullPath}`)
            // this.$router.push('/login')
            // this.$router.push({ path: '/login' })
            // window.location.reload() // 临时解决删除路由表
            // resetRouter()
            // window.sessionStorage.setItem('hasAddRoutes', 'false')
            // console.log(resetRouter)
        },
        // 跳转到关于页面
        goabout () {
            this.$router.push('/about')
        },
        submitForm (formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    // alert('submit!')
                    const data = {
                        new_password: this.ruleForm.checkPass
                    }
                    userApi.resetPassword(data).then(res => {
                        if (res) {
                            this.resetForm(formName)
                            this.$message({
                                message: '修改成功，下次登录请使用新密码！',
                                type: 'success'
                            })
                            this.dialogVisible = false
                        }
                    })
                } else {
                    // console.log('error submit!!')
                    return false
                }
            })
        },
        resetForm (formName) {
            this.$refs[formName].resetFields()
        },
        showMsg (data) {
            this.$message({
                message: data,
                type: 'success'
            })
        }

    }
}
</script>
<style lang="scss" scoped>
.el-header {
    background-color: #fff;
    color: #333;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .collapse {
        font-size: 24px;
        cursor: pointer;
    }
}
.header-right {
    display: flex;
    align-items: center;
    .sys-msg {
        display: flex;
        align-items: center;
        color: #71777d;
        margin-right: 16px;
        cursor: pointer;
        &:hover {
            color: #f01414;
        }
        i {
            font-size: 20px;
        }
        span {
            font-size: 14px;
        }
    }
    .el-dropdown-link {
        color: #777;
        font-size: 12px;
        font-weight: 600;
        cursor: pointer;
    }
}
</style>
