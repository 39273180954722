import { constantRoutes } from '@/router'
// import { asyncRoutes, constantRoutes } from '@/router' // asyncRoutes是前端配置的静态路由表
import Layout from '@/components/Layout/Index' // Layout 是架构组件，不在后台返回，在文件里单独引入
import Tools from '@/utils/tools'
const tools = new Tools()
// import store from '../../store'

/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission (roles, route) {
    // console.log(route.meta)
    // console.log(roles)
    if (route.meta && route.meta.roles) {
        return roles.some(role => route.meta.roles.includes(role))
    } else {
        return true
    }
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
export function filterAsyncRoutes (routes, roles) {
    const res = []
    // console.log()
    routes.forEach(route => {
        const tmp = { ...route }
        if (hasPermission(roles, tmp)) {
            if (tmp.children) {
                tmp.children = filterAsyncRoutes(tmp.children, roles)
            }
            res.push(tmp)
        }
    })

    return res
}

export const loadView = (view) => { // 路由懒加载
    // return (resolve) => require([`@/views/${view}`], resolve)
    // 要使用@babel/eslint-parser代替babel-eslint 否则这里会报错
    // return () => Promise.resolve().then(() => import(/* webpackChunkName: "[request]" */`@/views/${view}.vue`))
    // window.console.log(view)
    return () => import(/* webpackChunkName: "[request]" */`@/views/${view}`)
}

export const loadView1 = (view) => {
    // 使用 require 无法实现动态路由的懒加载
    if (process.env.NODE_ENV === 'development') {
        return (resolve) => require([`@/views/${view}`], resolve)
    } else {
        // 使用 import 能实现生产环境的路由懒加载，但是开发环境不行
        console.log(process.env.NODE_ENV)
        return () => import(`@/views/${view}.vue`)
    }
}

function generateAsyncRouter (menus) {
    menus.map(menu => {
        // console.log(menu.title, menu)
        if (menu.component) {
            if (menu.component === 'Layout') {
                menu.component = Layout
            } else {
                menu.component = loadView(menu.component)
            }
        }
        if (menu.children && menu.children.length) {
            // menu.children = generateAsyncRouter(menu.children)
            generateAsyncRouter(menu.children)
        }
        return true
    })
    return menus
}
const permission = {
    state: {
        routes: [], // 公共的不需要权限的路由表
        addRoutes: [] // 动态路由表
    },
    mutations: {
        SET_ROUTES: (state, routes) => {
            state.routes = constantRoutes.concat(routes)
            state.addRoutes = routes
            // console.log(state)
        }
    },
    actions: {
        // 根据角色生成路由表,使用前端配置的静态路由表
        /*
        generateRoutes ({ commit }, roles) {
            return new Promise(resolve => {
                let accessedRoutes
                if (roles.includes('系统管理员')) {
                    // console.log(33)
                    accessedRoutes = asyncRoutes || []
                    // console.log('generateRoutes-asyncRoutes', asyncRoutes)
                } else {
                    // console.log('not admin im ' + roles)
                    // 非管理员根据角色过滤路由表
                    accessedRoutes = filterAsyncRoutes(asyncRoutes, roles)
                }
                commit('SET_ROUTES', accessedRoutes) // 把路由表存到vuex 供渲染侧边栏菜单使用
                // console.log(store.state)
                // console.log(accessedRoutes)
                resolve(accessedRoutes)
                // resolve()
            })
        },
        */
        // 根据后端userInfo接口返回的menus数据生成路由表
        generateServerRoutersMap ({ commit }, menus) {
            return new Promise(resolve => {
                let accessedRoutes = []
                const treeData = tools.makeTree(menus)
                accessedRoutes = generateAsyncRouter(treeData)
                // console.log(treeData, accessedRoutes)
                commit('SET_ROUTES', accessedRoutes) // 把路由表存到vuex 供渲染侧边栏菜单使用
                // console.log(store.state)
                // console.log(accessedRoutes)
                resolve(accessedRoutes)
            })
        }
        //
    }
}
export default permission
